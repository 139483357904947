import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import {getArticleById} from "../services/api";
import marked from "marked";
import ShareButton from "../news/shareButton";
import SplashScreen from "../splash-screen";
import VimeoPlayer from "../components/vimeo-player";
import "../../scss/nyatc/nyatc_article.scss";
import EventBusPublisher from "./event-bus-publisher";
import { withRouter } from "react-router-dom";

let renderer = new marked.Renderer()

renderer.paragraph = text => {
  let paragraph = `<p>${text}</p>`;
  if (text.includes('<img'))
    paragraph = `<p class="article-image-container">${text}</p>`;
  return paragraph;
}

const getArticleImages = function (data) {
  if (data.vimeoId) {
    return <div className="video-container">
      <VimeoPlayer videoId={parseInt(data.vimeoId, 10)}/>
    </div>
  } else if (data.type === 'two-image') {
    const url0 = `url(${data.images[0]})`
    const url1 = `url(${data.images[1]})`
    return (
      <div className="images two-image">
        <div className="image left" style={{backgroundImage: url0}}/>
        <div className="image right" style={{backgroundImage: url1}}/>
        <div className="credits">{data.credits}</div>
        <div className="clearfix"/>
      </div>
    )
  } else if (data.type === 'one-image') {
    const url = `${data.images[0]}?w=970`
    return (
      <div className="images one-image">
        <img src={url} loading='lazy'/>
        <div className="clearfix"/>
        <div className="credits">{data.credits}</div>
      </div>
    )
  } else {
    return null
  }
}

export const NewsArticle = (props) => {
  const { location, match, history } = props;
  const query = new URLSearchParams(location.search);
  const page = parseInt(match.params?.page, 10) || 1;
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(true);
  const articleId = query.get("id");
  const articleRef = useRef(null);
  const content = useRef(null);

  useEffect(() => {
    getArticleData(articleId);

    const addEventListeners = setTimeout(() => {
      const anchorElements = document.querySelectorAll(".main-content.article a");
      anchorElements.forEach(anchorElement => {
        if (!anchorElement.dataset.listenerAdded) {
          anchorElement.addEventListener("click", onClickAnchorElement);
          anchorElement.dataset.listenerAdded = "true";
        }
      });
    }, 1000)

    return () => {
      clearTimeout(addEventListeners);
    }
  }, []);


  const onClickAnchorElement = function (clickEvent) {
    clickEvent.preventDefault();
    clickEvent.stopPropagation();

    const element = clickEvent.target;
    let url = element.getAttribute('href');
    const isInternalLink = (location.hostname === element.hostname || !element.hostname.length);
    if (isInternalLink) {
      if (url?.startsWith("/#/")) {
        url = url.replace(/^\/#/gi, "");
      }
      if (url.startsWith("http")) {
        const parsed = new URL(url);
        url = parsed.pathname + parsed.search;
      }
      return history.push(url);
      
    } else {
      return window.open(url, "_blank");
    }
  }

  const getArticleData = (id) => {
    getArticleById(id).then((data) => {
      const {article = {}} = data;
      setData(article);
      setLoading(false);
    })
  }

  const renderArticle = () => {
    const {
      headline,
      author,
      dateText,
      bodyText,
      column,
      bodyHeadline,
      headlinePhotoCaption,
      subBodyHeadline,
      page
    } = data

    return (
      <div id='main-content-article' className="main-content article" ref={articleRef}>
        <div id='column-article' className="column article">
          {bodyHeadline ?
            <div id='main-title' className={'title ' + column + ` news-${page?.slice(-1)} main-title`} ref={content}
                 dangerouslySetInnerHTML={{__html: marked(bodyHeadline || '')}}/> :
            <div id='main-title' className={'title ' + column + ` news-${page?.slice(-1)} main-title`}>
              <h1>{headline}</h1></div>}

          {/* caption */}
          <div className="headline-photo-caption">
            <span>{headlinePhotoCaption}</span>
          </div>

          {/* {!isMobile ? <ShareButton articleRef={articleRef}/> : null} */}

          {/* subbody */}
          <div className='subtitle-container'>
            <div id='subtitle' className={'title ' + column + ` news-${page?.slice(-1)} subtitle`} ref={content}
                 dangerouslySetInnerHTML={{__html: marked(subBodyHeadline || '')}}/>
          </div>


          <div className='article-text-container'>
            <div className="byline">
              <span className="author">{author}</span>
              <span className="date">{dateText}</span>
            </div>
            {getArticleImages(data)}
            <div id='article-text' ref={content} className="article-text"
                 dangerouslySetInnerHTML={{__html: marked(bodyText || '', {renderer})}}
            />
          </div>
        </div>
      </div>
    )
  }

  if (loading)
    return <SplashScreen style={{height: '100vh', width: '100%'}} loadState={100}/>
  return renderArticle();
}

const mapStateToProps = (state) => {
  return {}
}

export default withRouter(connect(mapStateToProps)(NewsArticle));

import React, { useEffect, useRef, useState } from "react";
import "../../scss/nyatc/reset.scss";
import "../../scss/nyatc/nyatc.newspaper.scss";
import NewspaperPageContent from "./content";

import { NewspaperFrame } from "./frame";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import EventBusPublisher from "./event-bus-publisher";
import NewsArticle from "./nyatc_article";
import Top40Tracks from "./top-40-tracks";
import { withRouter } from "react-router-dom";

export function NewspaperPage(props) {
  const { match, location } = props;

  const params = match.params || {};
  const query = new URLSearchParams(location.search);

  const page = parseInt(params.page, 10) || 1;
  const isArticleView =
    location.pathname.includes("article") && query.has("id");
  const isTop40Tracks = location.pathname.includes("top-40-tracks");

  if (isTop40Tracks) {
    return (
      <div className="newspaper-page">
        <div id="newspaper">
          <NewspaperFrame {...props} page={page}>
            <Top40Tracks />
          </NewspaperFrame>
        </div>
      </div>
    );
  }

  if (isArticleView) {
    return (
      <div className="newspaper-page">
        <div id="newspaper">
          <NewspaperFrame {...props} page={page} isArticleView={isArticleView}>
            <NewsArticle {...props} page={page} />
          </NewspaperFrame>
        </div>
      </div>
    );
  }

  return (
    <div className="newspaper-page">
      <div id="newspaper">
        <NewspaperFrame {...props} page={page}>
          <NewspaperPageContent
            {...props}
            page={page}
            isArticleView={isArticleView}
          />
        </NewspaperFrame>
      </div>
    </div>
  );
}

NewspaperPage.contextTypes = {
  router: PropTypes.object.isRequired,
  page: PropTypes.number,
};

const mapStateToProps = (state) => ({});

export default withRouter(connect(mapStateToProps)(NewspaperPage));

export default class EventBusPublisher {
  static changePage(page) {
    const pageValue = typeof page === "string" ? page : String(page);
    console.log("EventBusPublisher: enviando CHANGE_PAGE para:", pageValue);

    const message = { message: "CHANGE_PAGE", page: pageValue };
    window.parent.postMessage(message, "*");
  }
  static parentRedirect(url){
    const message = { message: 'PARENT_REDIRECT', parentUrl:url };
    window.parent.postMessage(message, '*');
  }

  static openExternalLink(url){
    const message = {message:"OPEN_EXTERNAL_LINK", url:url};
    window.parent.postMessage(message, '*');
  }

  static playTrackOnParentPlayer (track, tracks) {
    const message = {message:"PLAY_ON_PARENT_PLAYER", track, tracks};
    window.parent.postMessage(message, '*');
  }
}

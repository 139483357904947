import marked from "marked";
import React, { Component } from "react";
import cns from "classnames";
import PropTypes from "prop-types";
import VerticalCenter from "../components/vertical-center";
import MusicPlayer from "../components/music-player";
import Audio from "../components/new-player/audio";
import PlaylistManager from "../components/new-player/playlist-manager";
import {
  infoCardIsLockedByID,
  albumIsLockedByID,
  hasSubscriptionInfo,
} from "../user";
import { connect } from "react-redux";
import { hasAuth, getTopChart } from "../services/api";
import FlipMove from "react-flip-move";
import EventBusPublisher from "../newspaper-iframe/event-bus-publisher";
import { withRouter } from "react-router-dom";

class Track extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      hovered: false,
    };
  }

  render() {
    let {
      itemIds,
      item,
      ind,
      title,
      lastWeek,
      totalWeeks,
      link,
      currentlyPlaying,
      onPlay,
      shrink,
      viewItem,
      isAlbumRow,
    } = this.props;
    if (_.isEmpty(item)) return null;

    let { hovered } = this.state;

    let scale = 35 / 66; /* for scaling down the music player inline */
    let style = { transform: `scale(${scale})` };

    let onClickHandler = (e) => {
      if (
        e.target.closest(".album-icon") ||
        e.target.closest(".track-infocard-icon")
      ) {
        viewItem(item.id);
        return;
      }

      if (e.target.closest(".lastWeek") || e.target.closest(".totalWeeks")) {
        return;
      }

      if (!shrink && e.target.closest(".title")) {
        onPlay(item, itemIds, ind);
        return;
      }

      onPlay(item, itemIds, ind);
    };

    let onLockedClickHandler = (e) => {
      if (e.target.closest(".billboard-icon-wrapper")) {
        return;
      }

      window.parent.subs();
    };

    // Check if is available for free users
    const disabled = isAlbumRow
      ? albumIsLockedByID(item.id)
      : infoCardIsLockedByID(item.id);

    return (
      <div
        className={ind % 2 === 0 ? "row even" : "row odd"}
        onMouseEnter={(e) => {
          this.setState({ hovered: true });
        }}
        onMouseLeave={(e) => {
          this.setState({ hovered: false });
        }}
        onClick={!disabled ? onClickHandler : onLockedClickHandler}
      >
        {(currentlyPlaying && link) || (hovered && link) ? (
          <div className="music-wrapper-wrapper music-wrapper-wrapper--dummy">
            <div className="music-wrapper" style={style}>
              <MusicPlayer
                disabled={disabled}
                hovered={hovered}
                item={item}
                scaled={true}
                isAlbum={isAlbumRow}
                isInTrackListing
                makeStateInSync
                dummy
              />
            </div>
          </div>
        ) : (
          <div className={ind == 0 ? "cell number top" : "cell number"}>
            {ind + 1}
          </div>
        )}
        <div className="cell title">
          <div>{title}</div>
          {!shrink && hovered && link && (
            <div
              onClick={() => {
                this.props.history.push(link)
              }}
              className={
                isAlbumRow
                  ? "billboard-icon-wrapper album-icon-wrapper"
                  : "billboard-icon-wrapper track-infocard-icon-wrapper"
              }
            >
              {isAlbumRow ? (
                <img
                  className="album-icon"
                  src={item.albumViewImage}
                  loading="lazy"
                />
              ) : (
                <div className="track-infocard-icon"></div>
              )}
            </div>
          )}
        </div>
        {!shrink && <div className="cell lastWeek">{lastWeek}</div>}
        {!shrink && <div className="cell totalWeeks">{totalWeeks}</div>}
      </div>
    );
  }
}

function externalizeHrefs(el) {
  let links = el.querySelectorAll("a");
  for (let i = 0; i < links.length; i++) {
    let a = links[i];
    if (location.hostname === a.hostname || !a.hostname.length) continue;
    a.setAttribute("target", "_blank");
  }
}

class Billboard extends Component {
  constructor(props, context) {
    super(props, context);

    const isInEmbeddedNYATC = window.navigator
      ? window.navigator.userAgent.includes("nya-mobile") ||
        window.navigator.userAgent.includes("nya-embedded")
      : false;

    this.state = {
      currTrack: PlaylistManager.currentTrack(),
      items: [],
      isInEmbeddedNYATC,
    };
    this.audioCallback = this.audioCallback.bind(this);
    this.playlistCallback = this.playlistCallback.bind(this);
    this.viewInfoCard = this.viewInfoCard.bind(this);
    this.viewAlbumCard = this.viewAlbumCard.bind(this);
    this.onTrackElementClick = this.onTrackElementClick.bind(this);
    this.onAlbumElementClick = this.onAlbumElementClick.bind(this);
    this.fetchTopChartData = this.fetchTopChartData.bind(this);
    this.handleSetItems = this.handleSetItems.bind(this);
    this.INTERVAL_UPDATE_TOP_CHART =
      process.env.UPDATE_TOP_CHART_INTERVAL || 900000;
  }

  getTop40(topChart) {
    let topChartHasInfo =
      topChart && topChart.topList && topChart.topList.length;
    let { topList = [] } =
      (topChartHasInfo && topChart) || this.props.topChart.Top40 || {}; //getChart('Top40') || {}
    if (topChartHasInfo) {
      topList = topList.map((entry) => {
        let top40entry = (
          this.props.topChart.Top40 ? this.props.topChart.Top40.topList : []
        ).filter((track) => entry.orastreamId === track.orastreamId);
        top40entry = top40entry.length ? top40entry[0] : {};
        return { ...top40entry, ...entry };
      });
    }
    const items = topList.map((entry) => {
      let {
        orastreamID,
        song: title,
        totalWeeks,
        yesterday,
        trackId = false,
      } = entry;
      yesterday = yesterday === null ? "--" : yesterday;
      const link = trackId ? "/info-card?track=" + trackId : false;
      const item = {
        lastWeek: yesterday,
        totalWeeks,
        title,
        orastreamID,
        link,
        trackId,
      };
      return Object.assign({}, entry, item);
    });
    return items;
  }

  getTop10(topChart) {
    let topChartHasInfo =
      topChart && topChart.topList && topChart.topList.length;
    let { topList = [] } =
      (topChartHasInfo && topChart) || this.props.topChart.Top10 || {}; //getChart('Top10') || {}
    if (topChartHasInfo) {
      topList = topList.map((entry) => {
        let Top10entry = (
          this.props.topChart.Top10 ? this.props.topChart.Top10.topList : []
        ).filter((track) => entry.upc === track.upc);
        Top10entry = Top10entry.length ? Top10entry[0] : {};
        return { ...Top10entry, ...entry };
      });
    }
    const items = topList.map((entry) => {
      let { albumId = false, album: title, totalWeeks, lastWeek } = entry;
      lastWeek = lastWeek === null ? "--" : lastWeek;
      let item = {
        title,
        lastWeek,
        totalWeeks,
        link: albumId ? "/album?id=" + albumId : false,
        albumId,
      };
      return Object.assign({}, entry, item);
    });
    return items;
  }

  getTop10Byline() {
    const { updateString } = this.props.topChart.Top10 || {};
    return updateString || "";
  }

  getTop40Byline() {
    const { updateString } = this.props.topChart.Top40 || {};
    return updateString || "";
  }
  UNSAFE_componentWillReceiveProps({ which, topChartData }) {
    if (topChartData) {
      const isTop40 = which === "top40";
      this.handleSetItems({ isTop40, topList: topChartData });
    }
  }
  componentDidMount() {
    Audio.addCallback(this.audioCallback);
    PlaylistManager.addListener(this.playlistCallback);
    if (!this.props.topChartData || !this.props.topChartData.length) {
      this.fetchTopChartData();
      this.intervalId = setInterval(
        this.fetchTopChartData,
        this.INTERVAL_UPDATE_TOP_CHART
      );
    }
    if (!this.refs.about) return;
    externalizeHrefs(this.refs.about);
  }
  componentWillUnmount() {
    Audio.removeCallback(this.audioCallback);
    PlaylistManager.removeListener(this.playlistCallback);
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }
  async fetchTopChartData() {
    const { which } = this.props;
    const isTop40 = which === "top40";
    try {
      const topChart = (await getTopChart(isTop40 ? "top40" : "top10")) || {};
      const topList = isTop40 ? topChart.top40 : topChart.top10;
      this.handleSetItems({ isTop40, topList });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
  handleSetItems({ isTop40, topList }) {
    const items = isTop40
      ? this.getTop40({ topList })
      : this.getTop10({ topList });
    this.setState({ items, loading: false });
  }
  audioCallback({ state: playerStatus }) {
    this.setState({ playerStatus });
  }

  playlistCallback({ track: currTrack }) {
    this.setState({ currTrack });
  }

  viewInfoCard(id) {
    let { history } = this.props;
    // todo check if needs to be modal
    history.push(`/info-card?track=${id}`);
  }

  onTrackElementClick(track, tracks) {
    if (!track) return;

    //if not logged in show sub modal
    if (!track.free) {
      if (!hasAuth()) {
        window.parent.login();
        return;
      } else if (!hasSubscriptionInfo()) {
        window.parent.subs();
        return;
      }
    }

    if (this.state.isInEmbeddedNYATC) {
      EventBusPublisher.playTrackOnParentPlayer(track, tracks);
      return;
    }

    const { currTrack, playerStatus } = this.state;
    if (track.id === _.get(currTrack, "id")) {
      if (playerStatus === "LIVEPLAY") {
        PlaylistManager.pause();
      }

      if (playerStatus === "PAUSED") {
        PlaylistManager.play();
      }

      return;
    }

    // _OS.resetContext()
    PlaylistManager._playItemInMiddleItems(track, tracks);
  }

  viewAlbumCard(id) {
    let { history } = this.props;
    // todo check if needs to be modal
    history.push(`/album?id=${id}`);
  }

  onAlbumElementClick(album) {
    if (!album) return;

    if (this.state.isInEmbeddedNYATC) {
      return;
    }

    const { currTrack, playerStatus } = this.state;
    if (
      _.has(currTrack, "album.albumId") &&
      album.albumId === _.get(currTrack, "album.albumId")
    ) {
      if (playerStatus === "LIVEPLAY") {
        PlaylistManager.pause();
      }

      if (playerStatus === "PAUSED") {
        PlaylistManager.play();
      }

      return;
    }
    // _OS.resetContext()
    PlaylistManager.playAlbum(album);
  }

  render() {
    const { which, shrink, topChart, showNote } = this.props;
    const { history } = this.props;
    const { currTrack } = this.state;
    const isTop40 = which === "top40";
    const isProd = process.env.ENVIRONMENT === "production";
    const underMaintenance = isTop40
      ? topChart.Top40 &&
        (isProd
          ? topChart.Top40.underMaintenance
          : topChart.Top40.devMaintenance)
      : (topChart.Top10 &&
          (isProd
            ? topChart.Top10.underMaintenance
            : topChart.Top10.devMaintenance)) ||
        false;

    //hide track list if underMaintenance
    if (underMaintenance)
      return showNote ? <div className="maintenance"></div> : null;

    const byline = isTop40 ? this.getTop40Byline() : this.getTop10Byline();
    const today = isTop40 ? "Today" : "This Week";
    const yesterday = isTop40 ? "Yesterday" : "Last Week";

    return (
      <div
        className={cns("billboard " + which, { "billboard--shrink": shrink })}
      >
        <div className="billboard-contents">
          {shrink ? (
            <div
              onClick={() => {
                history.push(`/news/top-40-tracks`);
              }}
              style={{ cursor: "pointer" }}
            >
              <div className={"header " + which} />
            </div>
          ) : (
            <div className={"header " + which} />
          )}
          {!shrink && (
            <div className="row labels">
              <div className="cell number">{today}</div>
              <div className="cell title">
                <VerticalCenter>Title</VerticalCenter>
              </div>
              <div className="cell totalWeeks">{yesterday}</div>
              <div className="cell lastWeek">Total Weeks</div>
            </div>
          )}
          <div className="separator" />
          <FlipMove>
            {this.state.items.map(
              (
                { title, lastWeek, totalWeeks, link, upc, orastreamId },
                ind
              ) => {
                let itemKey = upc || orastreamId;
                let currentlyPlaying =
                  currTrack &&
                  (isTop40
                    ? currTrack.id
                    : currTrack.album
                    ? currTrack.album.albumId
                    : -1) === this.state.items[ind].id;
                return (
                  <Track
                    key={"track" + ind + itemKey}
                    ind={ind}
                    item={this.state.items[ind]}
                    shrink={shrink}
                    itemIds={this.state.items}
                    title={
                      shrink && title.length > 26
                        ? title.slice(0, 23).trim() + "..."
                        : title
                    }
                    lastWeek={lastWeek}
                    totalWeeks={totalWeeks}
                    link={link}
                    isAlbumRow={!isTop40}
                    currentlyPlaying={currentlyPlaying}
                    onPlay={
                      isTop40
                        ? this.onTrackElementClick
                        : this.onAlbumElementClick
                    }
                    viewItem={isTop40 ? this.viewInfoCard : this.viewAlbumCard}
                  />
                );
              }
            )}
          </FlipMove>
        </div>
        {!shrink && (
          <div
            className="about"
            ref="about"
            dangerouslySetInnerHTML={{ __html: marked(byline) }}
          />
        )}
      </div>
    );
  }
}

Billboard.contextTypes = {
  router: PropTypes.object.isRequired,
};

const mapStateToProps = function (state) {
  return {
    topChart: state.topCharts,
  };
};

export default  withRouter(connect(mapStateToProps)(Billboard));

import React, { useRef } from 'react';
import removeHash from '../services/remove-hash';
import EventBusPublisher from './event-bus-publisher';
import { withRouter } from 'react-router-dom';

const Ad = ({ link, image, history }) => {
 const linkRef = useRef(null);

 const handleInternalLinkClick = ({ event, path }) => {
  event.preventDefault();
  history.push("path");
 };

 const style = { objectFit: 'cover' };
 const imageElement = <img src={image} className="ad" style={style} loading='lazy'/>;
 const isAlbumLink = link.includes('album');
 const isExternalLink = link.startsWith('http');
 let path = removeHash(link);

 if (path && isAlbumLink) {
  path = `${path}&b=true`;
 }

 return (
  <div ref={linkRef}>
   {isExternalLink ? (
    <a href={path} target="_blank" rel="noopener noreferrer">
     {imageElement}
    </a>
   ) : (
    <a href={path} onClick={(event) => handleInternalLinkClick({ event, path })}>
     {imageElement}
    </a>
   )}
  </div>
 );
};

export default withRouter(Ad);

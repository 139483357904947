import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import VerticalCenter from "../../components/vertical-center";
import hasClass from "has-class";
import back from "../../util/back";
import marked from "marked";
import moment from "moment";
import ScrollBars from "react-scrollbar";
import { getPpvMovie, hasAuth, getRentedPpvMovie } from "../../services/api";
import { updateEntries } from "../../actions/entries";
import VimeoPlayer from "../../components/vimeo-player";
import { updateVideoStatus } from "../../actions/movie";
import { VIDEO_PLAYING } from "../../constants";
import { updatePpvData, updatePurchaseState } from "../../actions/ppv";
import StripeCheckout from "react-stripe-checkout";
import { canViewExclusiveContent } from "../../user";
import { getBrioVideoInformation } from "../../services/brio";
import {
  getUserInfo,
  createOrUpdateVideo,
  getRentals,
} from "../../services/api";
import nyaIcon from "../../../images/NYA_icon_128x128.jpg";
import SplashScreen from "../../splash-screen";
import vimeoPlayButtonImage from "../../../images/movie-night/vimeo-button.webp";
import Audio from "../../components/new-player/audio";
import { parse } from "circular-json";

class PPVModal extends Component {
  constructor(props, b) {
    super(props, b);

    this.state = { ppvCategory: "my-rentals", autoplay: false, state: null };

    this.onBGClick = this.onBGClick.bind(this);
    this.onCloseClick = this.onCloseClick.bind(this);
    this.onDetailsClick = this.onDetailsClick.bind(this);
    this.onRentClick = this.onRentClick.bind(this);
    this.onPreviewClick = this.onPreviewClick.bind(this);
    this.onPlayClick = this.onPlayClick.bind(this);
    this.onRentClick = this.onRentClick.bind(this);
    this.scrollTop = this.scrollTop.bind(this);
    this.selectGotToken = this.selectGotToken.bind(this);
    this.scrollDiv = React.createRef();
  }
  componentWillMount() {
    // resetMetadata();
  }
  componentDidMount() {
    const { ppvCategory, id } = this.props.match.params;
    //brio orastream id's
    if (id.length > 9) {
      getBrioVideoInformation(id).then((result) => {
        const { html: iframe, cover } = result;
        this.setState({ iframe, cover });
      });
    }
    if (hasAuth()) {
      getRentedPpvMovie(id).then((data) => {
        updateEntries(data.data);
      });
    } else {
      this.setState({ ppvCategory });
      getPpvMovie(ppvCategory, id).then((data) => {
        if (data && !data.video) {
          const query = new URLSearchParams(this.props.location.search);
          const categoryId = query.get("category");
          const video = query.get("osVideo");

          if (video) return;

          let modalLink = `movie-night/movietone?category${categoryId}`;
          if (categoryId) this.props.history.push(modalLink);
          return;
        }
        updateEntries(data);
      });
    }
  }
  onBGClick(e) {
    let el = e.target;
    if (
      el === this.refs.background ||
      hasClass(el, "album-modal-contents-center") ||
      hasClass(el, "album-modal-contents-center-inner") ||
      hasClass(el, "background-mousefix-left") ||
      hasClass(el, "background-mousefix-right")
    ) {
      e.preventDefault();
      e.stopPropagation();
      this.updateCategories();
      back(this.props.history);
    }
  }

  onCloseClick() {
    this.updateCategories();
    back(this.props.history);
  }

  updateCategories() {
    if (this.state.state === "purchasedSucceded") {
      getRentals().then((rentals) => {
        updatePpvData(rentals.data);
      });
    }
  }

  onDetailsClick(path) {
    this.props.history.push(path);
  }

  onPreviewClick() {
    //TODO: When Vimeo Story is done, Preview can be implemented
  }

  onPlayClick() {
    const movie = this.props.ppvMovie;
    const vimeoId = movie ? parseInt(movie.vimeoId) : null;
    const { availableForRental, price, rented = false } = movie;
    if (!movie) {
      return;
    } else if (
      movie &&
      !canViewExclusiveContent() &&
      !(rented && availableForRental && price)
    ) {
      if (!hasAuth()) {
        window.subs();
      } else {
        window.unlimitedAcced();
      }
      return;
    } else if (availableForRental && price && !rented) {
      return;
    }

    this.scrollTop();
    updateVideoStatus(vimeoId, VIDEO_PLAYING);
    this.setState({
      autoplay: true,
    });
  }

  onRentClick() {
    window.subs();
  }

  openExternalLink(url) {
    window.open(url, "_blank");
  }

  scrollTop() {
    this.scrollDiv.current.scrollIntoView({ behavior: "smooth" });
  }
  onPlay() {
    Audio.pause();
  }

  selectGotToken(token) {
    const { ppvMovie } = this.props;
    this.setState({ state: "paying" }, () => {
      createOrUpdateVideo(token, ppvMovie).then(
        (data) => {
          const { data: video, rentalCategory } = data;
          ppvMovie.rented = true;
          if (rentalCategory) ppvMovie.category = rentalCategory;
          if (video && video.period_start)
            ppvMovie.period_start = video.period_start;
          if (video && video.period_end)
            ppvMovie.expirationDate = video.period_end;
          updateEntries({ video: ppvMovie });
          updatePurchaseState({ state: true, purchasedMovie: ppvMovie });
          this.setState({ state: "purchasedSucceded" });
        },
        (err) => this.setState({ state: "paymentFail" })
      );
    });
  }

  renderButtons(ppvMovie, canWatch) {
    const {
      price,
      availableForRental = false,
      trailerUrl,
      expirationDate,
      buyLink,
      rented,
    } = ppvMovie;

    const userInfo = getUserInfo();

    let expirationText = "";
    if (expirationDate) {
      const date = moment.unix(expirationDate);
      expirationText = date.format("h[:]mma, MMMM Do, YYYY");
    }

    let videoPrice = price;
    //determine if it has floats
    let isFloat = price % 1 != 0;
    //add two decimals
    if (!isFloat) videoPrice = price.toFixed(2);

    //Price must be set in whole numbers not floats
    const productPrice = (videoPrice + "").replace(/[^0-9|-]/g, "");

    const email =
      (userInfo.user_metadata && userInfo.user_metadata.customEmail) ||
      userInfo.email;
    const canPuchase = hasAuth();
    //Depending if movie is rented or not, buttons will be different
    if (canWatch) {
      return (
        <div className="ppv-btns">
          <button onClick={this.onPlayClick}>Play</button>
          {canWatch && rented && (
            <div className="purchased-date">
              Expires at <br />
              {expirationText}
            </div>
          )}
          {buyLink && <div className="buy-btn" />}
        </div>
      );
    } else {
      return (
        <div className="ppv-btns">
          {!availableForRental && (
            <button onClick={this.onPlayClick}>Play</button>
          )}
          {trailerUrl && (
            <button onClick={() => this.openExternalLink(trailerUrl)}>
              Trailer
            </button>
          )}
          {/* {previewTimestamp ? (<button onClick={() => this.onPreviewClick()}>Preview</button>) : ""} */}
          {canPuchase && availableForRental ? (
            <StripeCheckout
              token={this.selectGotToken}
              stripeKey={process.env.STRIPE_KEY}
              email={email}
              billingAddress={true}
              amount={parseInt(productPrice)}
              image={nyaIcon}
            >
              <div className="rent-btn">
                <span>${price}</span>
              </div>
            </StripeCheckout>
          ) : (
            price &&
            availableForRental && (
              <div
                className="rent-btn"
                onClick={() => {
                  if (!hasAuth()) {
                    window.login();
                  }
                }}
              >
                <span>${price}</span>
              </div>
            )
          )}
          {buyLink && (
            <div
              className="buy-btn"
              onClick={() => this.openExternalLink(buyLink)}
            />
          )}
        </div>
      );
    }
  }

  renderContent() {
    const { ppvMovie, match } = this.props;
    const { id: requestedVideoId } = match.params;
    const { ppvCategory, autoplay } = this.state;
    let canWatch = canViewExclusiveContent();

    if (ppvMovie) {
      const {
        title,
        filmId,
        runningTime,
        creationDate,
        description,
        category,
        director,
        rented,
        price,
        availableForRental,
      } = ppvMovie;
      const { state } = this.state;

      const vimeoId = ppvMovie ? parseInt(ppvMovie.vimeoId) : 0;

      let ppvPlayerCategory = ppvCategory,
        style = { ["backgroundColor"]: "rgba(0,0,0,0.7)" },
        releaseDate = moment(creationDate).format("MMMM Do, YYYY");
      if (category) {
        ppvPlayerCategory = category.title.toLowerCase();
        ppvPlayerCategory = ppvPlayerCategory.replace(" ", "-");
      }

      if (price && availableForRental) {
        canWatch = rented ? true : false;
      }

      const isOrastreamVideo =
        ppvMovie && ppvMovie.orastreamVideoId != undefined;
      const videoId = isOrastreamVideo
        ? ppvMovie.orastreamVideoId
        : ppvMovie.vimeoId;

      return (
        <div
          ref="background"
          style={style}
          className="album-modal"
          onClick={this.onBGClick}
        >
          <VerticalCenter
            className="album-modal-contents-center"
            innerClassName="album-modal-contents-center-inner"
            onClick={this.onBGClick}
          >
            <div
              id="ppv-modal"
              className="album-modal-contents"
              ref={this.scrollDiv}
            >
              <div className="background ppv-background">
                <div
                  className="background-mousefix-left"
                  onClick={this.onBGClick}
                />
                <div
                  className="background-mousefix-right"
                  onClick={this.onBGClick}
                />
              </div>

              {state == "paying" ||
              !ppvMovie ||
              (requestedVideoId && requestedVideoId !== videoId) ? (
                <SplashScreen loadState={100} />
              ) : (
                <ScrollBars>
                  <div className="ppv-wrapper">
                    <div className={"album-modal-top"}>
                      <div
                        className={`hero`}
                        style={{
                          backgroundImage: `url('${category.imageOutline}')`,
                        }}
                      />

                      <div className={`video-container ${ppvPlayerCategory}`}>
                        {rented || canWatch ? (
                          isOrastreamVideo ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: this.state.iframe,
                              }}
                            />
                          ) : (
                            <VimeoPlayer
                              autoPlay={autoplay}
                              focus={true}
                              videoId={vimeoId}
                              onPlay={this.onPlay}
                            />
                          )
                        ) : (
                          <div
                            className="vimeo-wrapper"
                            onClick={() => {
                              if (!hasAuth() && availableForRental) {
                                window.login();
                              } else if (!hasAuth()) {
                                window.subs();
                              } else {
                                if (price && availableForRental) {
                                  return;
                                } else {
                                  window.unlimitedAcced();
                                }
                              }
                            }}
                          >
                            <img
                              className="vimeo-button"
                              src={vimeoPlayButtonImage}
                              alt="Vimeo Play Button"
                              loading="lazy"
                            />
                            {isOrastreamVideo && this.state.cover ? (
                              <img
                                className="video-cover"
                                src={this.state.cover}
                                loading="lazy"
                              />
                            ) : (
                              // <span dangerouslySetInnerHTML={{__html: this.state.iframe}} />
                              <VimeoPlayer
                                autoPlay={autoplay}
                                focus={true}
                                hideControls={true}
                                videoId={vimeoId}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="ppv-brief">
                      <h1>{title}</h1>
                      <p>Directed by: {director}</p>
                      {releaseDate && <p>Release Date: {releaseDate}</p>}
                      <p>Running Time: {runningTime}</p>
                      {filmId && (
                        <div
                          className="ppv-film"
                          onClick={() =>
                            this.onDetailsClick(`/film?id=${filmId}`)
                          }
                        >
                          <span>More Details</span>
                        </div>
                      )}
                    </div>
                    {this.renderButtons(ppvMovie, canWatch)}
                    {description && (
                      <div
                        className="ppv-description"
                        dangerouslySetInnerHTML={{
                          __html: marked(description || ""),
                        }}
                      />
                    )}
                  </div>
                </ScrollBars>
              )}
              <div className="close-button" onClick={this.onCloseClick} />
            </div>
          </VerticalCenter>
        </div>
      );
    } else {
      <div></div>;
    }
  }
  render() {
    return <div>{this.renderContent()}</div>;
  }
}

const mapStateToProps = function (state) {
  return {
    ppvMovie: state.ppvMovies.video,
  };
};

export default withRouter(connect(mapStateToProps)(PPVModal));
